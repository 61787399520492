$font-family: "Source Sans 3", sans-serif;
$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-light: 300;

//COLORES
// colores principales segun la identidad corporativa del negocio:
$accent-color-1: #fa5454;
$accent-color-2: #000000;
$accent-color-3: #404042;

$lightgrey: #f9f9f9;
$border-light: #efefef;

$gray: #2e2e2e;
$elegant-color-dark: #212121;
$success: #46be8a;
