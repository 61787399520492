.buttons_payments {
    display: flex;
    justify-content: flex-end;
}

.button_pay {
    width: 100%;
    @media (min-width: 768px) {
        width: 50%;
    }
}