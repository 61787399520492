@import 'variables';
@import 'colors';
//@import 'openpay';


*, *:before, *:after {
    box-sizing: inherit;
}
html {
    box-sizing: border-box;
    background: transparent;
}
html,
body {
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
h1 {
    font-size:28px;
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
}

h2 {
    font-size: 24px;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

h3 {
    font-size: 24px;

    &.subtitle {
        color: #000000;
        font-weight: 600;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid lightgray;
    }
}

.cursor-pointer {
    cursor: pointer;
}

header {
    .column-logo {
        display:flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 768px) {
            justify-content: flex-start;

        }
    }
    .column-info {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        @media (min-width: 768px) {
            justify-content: flex-end;
        }
        .info-contact {
            font-size: .9rem;
            font-weight: bold;
        }
    }
}
header,
footer{
    padding:1rem 0;
}
// FORM RESREVATION

label {
    font-size: 18px;
    color:#000;
    font-weight: 600;
}
button {
    svg {
        color:white;
    }
}

.confirmation-page,
.checkout-page {
    .data-information {
        padding: 24px;
        padding-top: 12px;
        margin-bottom: 24px;
        border-radius: 5px;
        h5 {
            margin: 24px 0;
        }
    }
}
.payment {
    .tab-content {
        background-color: $lightgrey;
        border-radius: 5px;
    }
    .shield {
        margin-top: 1rem;
        font-size: 12px;
    }
    button {
        font-weight: 600;
    }
    .button-pay {
        width: 50%;
    }
}
.modal-body {
    .data-information {
        padding: 0;
        padding-top: 0;
        margin-bottom:0;
        border-radius: 0;
        h5 {
            margin:20px 0;
        }
    }
    .reservations-policies {
        height: 300px;
        overflow-y: scroll;
        font-size: 12px;
    }
}
.input-group-text {
    width: 46px;
    display: flex;
    justify-content: center;
}
.tr-select,.tr-input {       
    flex-wrap: nowrap;
}
.select-transroute {
    width: 100%;
}
.tr-datepicker {
    width: 100%;
}
/* .tr__menu {
    min-height: 100%;
} */

.react-datepicker-wrapper {
    width: 100%;
}
.nav-item {
    cursor: pointer;
    text-align: center;
    svg {
        font-size: 2rem;
        margin-bottom: .5rem;
    }
}

input.tr-datepicker {
    padding: 6px 12px;
    border: 1px solid #ced4da;
    color: #404041;
}



footer {
    font-size: .7rem;
    text-align: center;
    @media (min-width: 768px) {
        text-align: left;
    }
}
#payment-form {

    label {
        font-size: 14px;
        font-weight: 600;
    }

}

.buttons-payments {
    display: flex;
    justify-content: space-between;
}
.Copy__footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    svg {
        margin-left: 1rem;
    }

}

.PaymentLogoGrid {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
}

.StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }


.link-benefits {
    &:hover {
        color: blue;
    }
}

.benefits {
    border: 3px dotted $accent-color-1;
    padding: 16px;
    margin: 24px 0;
    .excerpt {
        font-size: 18px;
        p {
            margin-bottom: 0 !important;
            font-weight: 600;
        }
    }
}
.invalid-feedback {
    display: block;
}

.Copy__footer .PaymentLogoGrid  {
    svg {
        &:last-child {display:none}

    }
}
.fa {
    color: $accent-color-1;
}
.warning-hotel-pickup {
    border: 1px solid #b00505;
    border-radius: 8px;
    padding: 1rem;
    color: #b00505;
    font-weight: 600;
}
