
@import '../../scss/variables';
.container {
    border:1px solid #000000;
    border-radius: 8px;
    padding: 1rem;
    display: grid;
    grid-template-columns:10% 45% 20% 20%;
    align-items: center;
}
.icon_container {
    background-color: lighten($accent-color-1, 10)  ;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        color:white
    }
}
